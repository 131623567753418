import { SdbProject } from "@custom-types/project-types";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import {
  HasUserValidRoleProjectLevelProps,
  RequiredRoleProjectLevelName,
  RequiredRolesProjectLevel,
} from "@utils/access-control/project/project-access-control-types";
import { hasUserValidCompanyRole } from "@utils/access-control/company/company-access-control";

const companyRole = CoreAPITypes.EUserCompanyRole;
const projectRole = CoreAPITypes.EUserProjectRole;

/**
 * Object that determines all different permission rules for users to get
 * access on the project level.
 */
export const requiredRolesProjectLevel: RequiredRolesProjectLevel<RequiredRoleProjectLevelName> =
  {
    /** Whether the user can edit details from a project like name or description */
    [RequiredRoleProjectLevelName.canEditProjectDetails]: {
      companyRoles: [companyRole.companyExecutive],
      projectRoles: [projectRole.owner, projectRole.admin],
    },

    /** Whether the user can view the cloud activity for a particular project */
    [RequiredRoleProjectLevelName.canViewProjectCloudActivity]: {
      companyRoles: [companyRole.companyExecutive],
      projectRoles: [projectRole.owner, projectRole.admin, projectRole.editor],
    },

    /**
     * Whether the user can view the teams tab in project overview page
     * and view all users with access to the project
     */
    [RequiredRoleProjectLevelName.canViewAllProjectMembers]: {
      companyRoles: [companyRole.companyExecutive, companyRole.companyViewer],
      projectRoles: [projectRole.owner, projectRole.admin],
    },

    /** Whether the user can view the settings for a particular project */
    [RequiredRoleProjectLevelName.canViewProjectSettings]: {
      companyRoles: [companyRole.companyExecutive, companyRole.companyViewer],
      projectRoles: [],
      projectManager: { isProjectManagerOfProject: true },
      companyManager: { shouldBelongToGroup: true },
    },

    /** Whether the user can archive projects. */
    [RequiredRoleProjectLevelName.canArchiveProjects]: {
      companyRoles: [companyRole.companyExecutive],
      // Having project roles is irrelevant in this case, even the project admin
      // can't archive or unarchive the project.
      projectRoles: [],
      projectManager: { isProjectManagerOfProject: true },
      companyManager: { shouldBelongToGroup: true },
      projectSubscriptionRoles: [APITypes.EUserSubscriptionRole.projectArchive],
    },

    /** Whether the user can unarchive projects. */
    [RequiredRoleProjectLevelName.canUnarchiveProjects]: {
      companyRoles: [companyRole.companyExecutive],
      // Having project roles is irrelevant in this case, even the project admin
      // can't archive or unarchive the project.
      projectRoles: [],
      projectManager: { isProjectManagerOfProject: true },
      companyManager: { shouldBelongToGroup: true },
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.projectUnarchive,
      ],
    },

    /** Whether the user can share the project where they have a role */
    [RequiredRoleProjectLevelName.canInviteUsersToPrivateProject]: {
      companyRoles: [
        companyRole.companyExecutive,
        companyRole.companyManager,
        companyRole.projectManager,
      ],
      projectRoles: [projectRole.owner, projectRole.admin],
    },

    /** Whether the user can change the settings for a particular project */
    [RequiredRoleProjectLevelName.canEditProjectSettings]: {
      companyRoles: [companyRole.companyExecutive],
      projectRoles: [],
      projectManager: { isProjectManagerOfProject: true },
    },

    /** Whether the user can delete a member from a project */
    [RequiredRoleProjectLevelName.canDeleteMemberFromProject]: {
      companyRoles: [
        companyRole.companyExecutive,
        companyRole.companyManager,
        companyRole.projectManager,
      ],
      projectRoles: [projectRole.owner, projectRole.admin],
    },

    /** Whether the user has permission to view video mode setting */
    [RequiredRoleProjectLevelName.canViewVideoModeFeature]: {
      // Same as `viewProjectSettings` plus the `globalVideoMode` subscription
      companyRoles: [companyRole.companyExecutive, companyRole.companyViewer],
      projectRoles: [],
      projectManager: { isProjectManagerOfProject: true },
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.globalVideoMode,
      ],
    },

    /** Whether the user has permission to view 2d images setting */
    [RequiredRoleProjectLevelName.canView2dImagesFeature]: {
      // Same as `viewProjectSettings` plus the `ft_image_2d` subscription
      companyRoles: [companyRole.companyExecutive, companyRole.companyViewer],
      projectRoles: [],
      projectManager: { isProjectManagerOfProject: true },
      projectSubscriptionRoles: [APITypes.EUserSubscriptionRole.ft_image_2d],
    },

    /** Whether the user has permission to view face blurring setting */
    [RequiredRoleProjectLevelName.canViewFaceBlurringFeature]: {
      // Same as `viewProjectSettings` plus the `globalFaceBlurring` subscription
      companyRoles: [companyRole.companyExecutive, companyRole.companyViewer],
      projectRoles: [],
      projectManager: { isProjectManagerOfProject: true },
      companyManager: { shouldBelongToGroup: true },
      projectSubscriptionRoles: [
        APITypes.EUserSubscriptionRole.globalFaceBlurring,
      ],
    },

    /** Whether the user has permission to delete a project */
    [RequiredRoleProjectLevelName.canDeleteProject]: {
      companyRoles: [companyRole.companyExecutive],
      projectRoles: [],
      projectManager: { isProjectManagerOfProject: true },
      companyManager: { shouldBelongToGroup: true },
    },

    /** Whether the user has permission to delete snapshots from a project */
    [RequiredRoleProjectLevelName.canDeleteSnapshots]: {
      companyRoles: [
        companyRole.companyExecutive,
        companyRole.companyManager,
        companyRole.projectManager,
      ],
      projectRoles: [projectRole.owner, projectRole.admin],
    },

    /** Whether the user can view the markups (annotations) of a project */
    [RequiredRoleProjectLevelName.canViewProjectMarkups]: {
      companyRoles: [companyRole.companyExecutive],
      projectRoles: [projectRole.owner, projectRole.admin, projectRole.editor],
    },

    /** Whether the user can view the markups (annotations) of a project. Project viewers are allowed */
    [RequiredRoleProjectLevelName.canViewProjectMarkupsAllowViewers]: {
      companyRoles: [companyRole.companyExecutive],
      projectRoles: [
        projectRole.owner,
        projectRole.admin,
        projectRole.editor,
        projectRole.viewer,
      ],
    },

    /** Whether the user can view the project stats chart in the project overview page */
    [RequiredRoleProjectLevelName.canViewProjectsStats]: {
      companyRoles: [
        companyRole.companyExecutive,
        companyRole.companyManager,
        companyRole.projectManager,
        companyRole.companyViewer,
      ],
      projectRoles: [projectRole.owner, projectRole.admin],
    },

    /** Whether the user can view the project data */
    [RequiredRoleProjectLevelName.canViewProjectData]: {
      companyRoles: [companyRole.companyExecutive],
      projectRoles: [projectRole.owner, projectRole.admin, projectRole.editor],
    },

    /** Whether the user can edit the markup details */
    [RequiredRoleProjectLevelName.canEditProjectMarkups]: {
      companyRoles: [companyRole.companyExecutive],
      projectRoles: [projectRole.owner, projectRole.admin, projectRole.editor],
      projectManager: { isProjectManagerOfProject: true },
    },

    /** Whether the user can view data management */
    [RequiredRoleProjectLevelName.canViewDataManagement]: {
      companyRoles: [companyRole.companyExecutive],
      projectRoles: [projectRole.owner, projectRole.admin, projectRole.editor],
    },
  };

/**
 * Checks whether a user has at least one of the required project roles.
 * !: Only use this method internally
 * To check for user access control better use hasUserValidRoleProjectLevel.
 * In some cases the user might not have the required project role but could still
 * have access because a different role within the group or an specific company role.
 *
 * @param currentUser The user to get its project role from.
 * @param selectedProject The selected project in which the user is expected
 * to have one of the required roles
 * @param requiredProjectRoles Array filled with project roles
 * that the user should have one of them
 * @returns True if the user has one of the required project roles.
 */
export function hasUserValidProjectRole({
  currentUser,
  selectedProject,
  requiredProjectRoles,
}: {
  currentUser: SphereDashboardAPITypes.ICompanyMemberBase | null;
  selectedProject: SdbProject | null;
  requiredProjectRoles: CoreAPITypes.EUserProjectRole[];
}): boolean {
  // If we don't have a selected project then we assume the user does not have access.
  if (!selectedProject) {
    return false;
  }

  // If the user has access to the project we know that at least he is a viewer
  if (
    !requiredProjectRoles ||
    requiredProjectRoles.length === 0 ||
    requiredProjectRoles?.includes(CoreAPITypes.EUserProjectRole.viewer)
  ) {
    return true;
  }

  // If the project includes the role property, use it to determine the user's access level.
  if (selectedProject.role) {
    return requiredProjectRoles.includes(selectedProject.role);
  }

  // If we didn't have the "role" property in the project, we can use the
  // members property to look for the user's role.
  // If this property is also not available we just can't determine the user's role.
  if (selectedProject.members.length === 0 || !currentUser) {
    return false;
  }

  // First find the selected user in the project members array.
  const memberInProject = selectedProject.members.find(
    (member) => member.identity === currentUser.identity
  );

  if (!memberInProject) {
    // If the user is not a member in the project
    return false;
  }

  return requiredProjectRoles.includes(memberInProject.role);
}

/**
 * Checks whether a user has permissions within the project level.
 * This check is more complex that just the project role, because in some cases the user
 * might not have the required project role but could still have access because a different
 * role within the group or an specific company role.
 *
 * @returns True if the user has a valid project level role or permission.
 */
export function hasUserValidRoleProjectLevel<
  RoleNameT extends string = RequiredRoleProjectLevelName
>({
  roleName,
  currentUser,
  selectedProject,
  defaultRequiredRolesProjectLevel = requiredRolesProjectLevel as RequiredRolesProjectLevel<RoleNameT>,
  projectContext,
}: HasUserValidRoleProjectLevelProps<RoleNameT>): boolean {
  if (!selectedProject || !currentUser) {
    return false;
  }

  // Check first if user has enabled project level subscription roles
  // Since the subscription access is a required condition the roles should be checked first
  // Early exit with false here if the subscription is not available
  if (
    defaultRequiredRolesProjectLevel[roleName].projectSubscriptionRoles &&
    !hasSubscriptionProjectLevel({
      projectContext: projectContext ?? null,
      requiredProjectSubscriptionRole:
        defaultRequiredRolesProjectLevel[roleName].projectSubscriptionRoles,
    })
  ) {
    return false;
  }

  const requiredRules = defaultRequiredRolesProjectLevel[roleName];
  const requiredCompanyRoles = requiredRules.companyRoles;
  if (
    requiredCompanyRoles &&
    requiredCompanyRoles.length &&
    hasUserValidCompanyRole({
      currentUser,
      requiredCompanyRoles,
    })
  ) {
    return true;
  }

  const requiredProjectRoles = requiredRules.projectRoles;
  if (
    requiredProjectRoles.length > 0 &&
    hasUserValidProjectRole({
      currentUser,
      selectedProject,
      requiredProjectRoles,
    })
  ) {
    return true;
  }

  // Check for the custom permission to allow users whenever they have both the project manager role
  // and they are the project managers of the project
  if (
    requiredRules.projectManager?.isProjectManagerOfProject &&
    // The user needs to have either the project manager role or the group manager in the company.
    hasUserValidCompanyRole({
      currentUser,
      requiredCompanyRoles: [
        companyRole.projectManager,
        companyRole.companyManager,
      ],
    }) &&
    selectedProject.managers.projectManager?.identity &&
    // The project manager for the project should be the current user.
    selectedProject.managers.projectManager?.identity === currentUser?.identity
  ) {
    return true;
  }

  // Check for the custom permission to allow users whenever they have both the group manager role
  // and are the group managers of the project.
  if (
    requiredRules.companyManager?.shouldBelongToGroup &&
    // The user needs to have the project manager role.
    hasUserValidCompanyRole({
      currentUser,
      requiredCompanyRoles: [companyRole.companyManager],
    }) &&
    selectedProject.managers.companyManager?.identity &&
    // The project manager for the project should be the current user.
    selectedProject.managers.companyManager?.identity === currentUser?.identity
  ) {
    return true;
  }

  // If the user didn't match any of the rules above it means it
  // does not have permission.
  return false;
}

/**
 * Checks whether a project has a subscription.
 *
 * @returns True if the project has required subscription.
 */
export function hasSubscriptionProjectLevel({
  projectContext,
  requiredProjectSubscriptionRole,
}: {
  projectContext: SphereDashboardAPITypes.IProjectContextResponse | null;
  requiredProjectSubscriptionRole?: APITypes.EUserSubscriptionRole[];
}): boolean {
  /**
   * Early return if there is no required project subscription
   * In this case there is no required subscriptions
   */
  if (!requiredProjectSubscriptionRole?.length) {
    return true;
  }

  /**
   * Early return if no roles in project context
   * In this case required subscription is not available in project context
   * as the roles in projectContext is empty
   */
  if (!projectContext || !projectContext.roles.length) {
    return false;
  }

  // Return true if all required subscription role is available
  return requiredProjectSubscriptionRole.every((requiredSubscriptionRole) =>
    projectContext.roles.includes(requiredSubscriptionRole)
  );
}
